import { graphql } from "gatsby"
import React, { useState, useEffect, useContext } from "react"
import tw from "twin.macro"
import TranslateClient from "../../clients/TranslateClient"
import { BackgroundContainer } from "../../components/BackgroundContainer"
import ContactForm from "../../components/Contact/ContactForm"
import ImageHero from "../../components/Hero/ImageHero"
import { SEO } from "../../components/SEO"
import { H1 } from "../../components/Typography"
import { LanguageContext } from "../../context/Language"
import { FormPageTemplateProps } from "./form-page-template.d"
// eslint-disable-next-line @typescript-eslint/no-var-requires
const { getStringValuesArray, mapValuesToObject } = require("../../helpers.js")

const FormPageTemplate: React.FC<FormPageTemplateProps> = ({ data, location }) => {
  const { title, content, featuredImage, template } = data.wpPage
  const [text, setText] = useState({ title, content })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ title, content })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([title, content])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  return (
    <div className="page-content">
      <SEO url={location.href} title={title} />
      <ImageHero image={featuredImage} overlay={false}>
        <div css={tw`text-center text-white container m-auto p-4 md:p-8`}>
          <H1
            css={[
              tw`text-4xl font-extrabold sm:text-5xl xl:text-6xl`,
              "text-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);",
            ]}
          >
            {text?.title}
          </H1>
        </div>
      </ImageHero>
      <BackgroundContainer css={tw`py-16`}>
        <section
          css={tw`container m-auto px-4 space-y-4 md:p-8`}
          dangerouslySetInnerHTML={{ __html: text?.content }}
        />
        <ContactForm
          resource="contact"
          headingStyle={tw`text-center w-full`}
          formTitle={template?.formPage?.formPageFormTitle}
          css={tw`mx-auto container xl:max-w-[1024px] 2xl:max-w-[1024px]`}
        />
      </BackgroundContainer>
    </div>
  )
}

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      ...PageData
      template {
        ... on WpFormPageTemplate {
          templateName
          formPage {
            formPageFormTitle
          }
        }
      }
    }
  }
`

export default FormPageTemplate
